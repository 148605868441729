import { getCurrentInstance, createVNode } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
export default function useDelete() {
    const { proxy } = getCurrentInstance();
    const useDeleteFun = (apiUrl, selectedRowsId) => {
        return new Promise((cback, reject) => {
            Modal.confirm({
                title: () => '确定删除该信息吗?',
                icon: () => createVNode(ExclamationCircleOutlined),
                okType: 'danger',
                onOk() {
                    proxy.http.post(apiUrl, { id: selectedRowsId }).then(re => {
                        proxy.message.success('删除成功');
                        cback()
                    })
                },
                onCancel() {
                    reject()
                }
            })
        })
    }

    return {
        useDeleteFun
    }
}