<!--家庭教育管理-->
<template>
    <div>
        <div class="searchBox flex">
            <a-button type="primary" class="marginLeft" @click="handleEdit()">
                <PlusCircleOutlined />添加家庭教育类别
            </a-button>
        </div>
        <a-row class="tableBox">
            <a-table :dataSource="list" :columns="columns" rowKey="id" :pagination="false" :scroll="{ y: 'calc(100vh - 390px)' }">
                <template #img="{text, record, index}">
                    <a-image :width="60" :src="domain+text" :fallback="domain+'/default.png'" style="border-radius:4px" />
                </template>
                <template #operation="{ record }">
                    <a-space>
                        <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
                        <a-button type="primary" @click="handleDelete(record)">删除</a-button>
                    </a-space>
                </template>
            </a-table>
        </a-row>

        <DialogAssembly ref="DialogRef" :title="title" @handleConfirm="handleConfirm" @handleCancle="handleCancle">
            <a-form ref="formRef" :model="Form" :rules="rules" layout="vertical">
                <a-form-item label="分类名称" name="name">
                    <a-input v-model:value="Form.name" placeholder="请输入分类名称" />
                </a-form-item>
            </a-form>
        </DialogAssembly>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, onMounted, createVNode } from 'vue';
import TablePagination from '@/components/TablePagination';
import DialogAssembly from '@/components/DialogAssembly';
import UploadImage from '@/components/UploadImage';
import useDelete from '@/hooks/useDelete.js';
import { PlusCircleOutlined } from '@ant-design/icons-vue';

export default defineComponent({
    components: {
        TablePagination,
        DialogAssembly,
        PlusCircleOutlined,
        UploadImage
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const { useDeleteFun } = useDelete();
        const formRef = ref();
        const DialogRef = ref();
        onMounted(() => {
            getList()
        });
        const initData = reactive({
            Form: {},
            list: [],
            columns: [
                { title: '分类名称', dataIndex: 'name' },
                { title: '录入时间', dataIndex: 'create_time' },
                { title: '操作', fixed: 'right', width: 200, slots: { customRender: 'operation' } },
            ],
            rules: {
                name: [{
                    required: true,
                    message: '请输入类别名称',
                }]
            },
            title: '添加家庭教育',
            domain: proxy.domain,
        });

        const getList = () => {
            proxy.http.post('/admin.cateHome/index').then(response => {
                initData.list = response;
            })
        };

        //新建弹框
        const handleEdit = (row) => {
            initData.title = row ? '编辑家庭教育' : '添加家庭教育';
            initData.Form = row ? JSON.parse(JSON.stringify(row)) : {};
            DialogRef.value.handleOpen();
        };

        //新建弹框确定
        const handleConfirm = () => {
            formRef.value.validate().then(() => {
                proxy.http.post('/admin.cateHome/save', initData.Form).then(() => {
                    DialogRef.value.handleCancel();
                    getList();
                })
            })
        };

        //新建弹框取消
        const handleCancle = () => {
            formRef.value.resetFields();
        };

        //删除
        const handleDelete = (row) => {
            useDeleteFun('/admin.cateHome/delete', [row.id]).then(() => {
                getList();
            })
        }
        //上传图片
        const uploadImage = (event) => {
            initData.Form.img = event;
        };

        return {
            ...toRefs(initData),
            getList,
            DialogRef,
            formRef,
            handleEdit,
            handleConfirm,
            handleCancle,
            handleDelete,
            uploadImage
        }
    }
})
</script>